<template>
    <div class="header">
        <!-- 导航菜单 -->
        <div class="bot">
            <div class="global_center flexBetCenter botNav">
                <!-- logo -->
                <div class="logo flexCenter">
                    <img src="@/assets/images/7data-logo.png" alt="">
                </div>
                <!-- 导航菜单 -->
                <NavMenu class="navMenu"></NavMenu>
            </div>
        </div>


    </div>
</template>
<script>
import NavMenu from './NavMenus.vue'
export default {
    name: 'Header',
    components: { NavMenu },
    data() {
        return {
        };
    },
    computed: {
    },
    created() {
    },
    methods: {
    }
}
</script>
<style lang="less" scoped>
.header {
    width: 100%;
    background: #fff;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 10px #eee;
    z-index: 3;

    .bot {
        .logo {

            img {
                width: 55px;
                height: 55px;
            }
        }

        .botNav {}
    }
}
</style>
<style lang="less">
.header {}
</style>