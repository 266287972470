<template>
    <el-menu :default-active="activeMenu" class="el-menu-demo" mode="horizontal" text-color="#333"
        active-text-color="rgb(44 64 205)" router>
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/service">业务范围</el-menu-item>
        <el-menu-item index="/case">主要产品</el-menu-item>
        <el-menu-item index="/xqy">小七云</el-menu-item>
        <el-menu-item index="/contact_us">联系我们</el-menu-item>
    </el-menu>
</template>
<script>
export default {
    name: 'NavMenus',
    data() {
        return {
        };
    },
    computed: {
        activeMenu() {
            const route = this.$route
            const { meta, path } = route
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
    },
    methods: {
    }
}
</script>
<style lang="less">
@link-color: #384499;

.el-menu {
    font-family: SourceHanSansCN-Normal;

    &.el-menu--horizontal {
        border-bottom: 0 !important;
    }

    .is-active,
    .is-active .el-submenu__title {
        border-bottom: 0 !important;
    }

    .el-submenu__title i {
        color: #FFFFFF;
    }

    .el-submenu__title,
    .el-dropdown-menu__item,
    .el-menu-item {
        font-size: 16px !important;

        i {
            display: none;
        }
    }


}

.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    border-bottom: 4px solid @link-color !important;
}

.el-menu--popup {
    padding: 0 !important;
}

.el-menu--popup-bottom-start {
    margin-top: 0 !important;
}

.el-menu--popup .is-active {
    border-bottom: 0 !important;
}

.el-menu--horizontal {
    text-align: center;

    .el-menu--popup .el-menu-item:not(.is-disabled):hover {
        color: #FFFFFF !important;
        background: @link-color !important;
    }
}
</style>
  