import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 全局样式
import '@/assets/style/index.less'

import { getPath } from '@/utils/common';
Vue.prototype.$getPath = getPath
Vue.prototype.$imgUrl = "https://www.west7data.com/qdgw/assets/"
Vue.prototype.$fileUrl = "https://www.west7data.com/qdgw/file/"

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
