<template>
    <div class="breadcrumb" v-if="levelList && levelList.length > 0">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item v-if="$route.meta.needHome">
                <a @click.prevent="handleLink({ path: '/' })" class="redirect">首页</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="url1 == 'supply' || url1 == 'transaction'">
                <span class="noRedirect">交易项目</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
                <span v-if="item.meta.redirect == false || index == levelList.length - 1" class="noRedirect">{{
                    item.meta.title }}</span>
                <a v-else @click.prevent="handleLink(item)" class="redirect">{{ item.meta.title }}</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
export default {
    name: 'Breadcrumb',
    data() {
        return {
            levelList: null,
            url1: this.$getPath(this.$route.path)[1],
        }
    },
    watch: {
        $route(newData) {
            this.getBreadcrumb()
            this.url1 = this.$getPath(newData.path)[1]
        }
    },
    created() {
        this.getBreadcrumb()
    },
    methods: {
        getBreadcrumb() {
            this.levelList = this.$route.matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
        },
        handleLink(item) {
            if (this.$getPath(this.$route.path)[1] == 'search') {
                this.$router.go(-1)
            } else {
                const { path } = item
                this.$router.push(path)
            }

        }
    }
}
</script>
<style lang="less" scoped>
.breadcrumb {
    padding: 20px 0;

    .noRedirect {
        font-size: 14px;
        color: #9FAEC3;
    }

    .redirect {
        font-size: 14px;
        color: #303133;
        font-weight: 400;
    }
}
</style>