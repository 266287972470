<template>
  <div class="home">
    <div class="home_top">
      <!-- 轮播图 -->
      <el-carousel height="400px" class="home_top_carous">
        <el-carousel-item v-for="item, index in carousList" :key="index" class="carousel_item flexCenter"
          :style="'background-image:url(' + require('@/assets/images/' + item.imgUrl) + ');'">
          <div>
            <div class="enDesc">{{ item.enDesc }}</div>
            <div class="chDesc">{{ item.chDesc }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- 公司简介 -->
      <div class="GSJJ global_center">
        <div class="tit">
          <p class="name">公司简介</p>
          <p class="bgname">COMPANY PROFILE</p>
        </div>
        <div class="js">
          <p class="js1">兰州七度数聚技术有限公司成立于2019年1月，位于兰州市安宁区万新南路高新什字安宁区科技产业孵化园内。注册资金人民币500万元。获得软件著作权 26 项、申报发明专利 2
            项，是一家专注于互联网技术及大数据应用技术研究开发的专业技术服务商。</p>
          <p class="js2">2021年被认定为甘肃省省级科技创新型企业，2023年被认定为高新技术企业。是中国人工智能学会会员单位、东方国信科技股份有限公司西北代表处承建单位。</p>
        </div>
      </div>
      <div class="global_center">
        <!-- 朝气蓬勃 -->
        <div class="zqpb">
          <div class="pbTit">
            <div class="tit1">朝气蓬勃</div>
            <div class="tit2">年轻团队驱动创新发展</div>
          </div>
          <div class="zqpb_tdnl flexBet">
            <div class="left">
              <div class="st">团队能力</div>
              <div class="info flexBet">
                <div class="list" v-for="item, index in tdnlData" :key="index">
                  <img :src="$imgUrl + item.imgUrl" alt="">
                  <div class="desc">{{ item.ssfl }}</div>
                </div>
              </div>
            </div>
            <div class="right">
              公司现有员工17名，其中专业技术人员12名，大数据及计算机科学领域博士2名，软件工程领域硕士3名，平均年龄在35岁以下，是一支朝气蓬勃、积极向上的创业团队。
            </div>
          </div>
        </div>
        <!-- 价值创新 -->
        <div class="jzcx">
          <div class="pbTit">
            <div class="tit1">价值创新</div>
            <div class="tit2">企业文化引领核心理念</div>
          </div>
          <div class="jzcx_info flexBet">
            <div class="list" v-for="item, index in jzcxlist" :key="index">
              <div class="name">{{ item.name }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
        <!-- 拓展服务领域 -->
        <div class="fwly">
          <div class="pbTit">
            <div class="tit1">拓展服务领域</div>
            <div class="tit2">智能科技全方位赋能</div>
          </div>
          <div class="fwly_info">
            业务能力涉及互联网、物联网及人工智能领域内的信息技术开发、推广和服务；智能制造和工业自动化领域内的信息技术开发、推广和服务；工业大数据平台开发和应用开发；计算机软件开发和系统集成；移动互联网应用程序开发及运营服务等领域。可承接各行业领域的“互联网+”整体解决方案，可根据不同行业客户的不同需求，专项开发微信/支付宝小程序，微信公众号、APP等各类互联网、物联网平台。
          </div>
        </div>
        <!-- 信息技术创新 -->
        <div class="jscx">
          <div class="pbTit">
            <div class="tit1">信息技术创新</div>
            <div class="tit2">七度数聚助力AI时代</div>
          </div>
          <div class="jscx_info">
            公司坚持原始技术创新和个性化定制开发，以社会计算、知识图谱、移动群智感知物联网和深度学习四个领域的多项核心技术，打造七度数聚大数据、AI平台，推出了“小七云”数字信息公共服务开放平台，“小七公益”系列公益小程序等多种类、多模式的数字信息产品。
          </div>
        </div>
        <!-- 资质证书 -->
        <div class="zzzs">
          <div class="pbTit">
            <div class="tit1">资质证书</div>
          </div>
          <div class="zzzs_info flexBet">
            <div class="listItem" v-for="item, index in zzzsurl.slice(0, 3)" :key="'t' + index">
              <img @click="viewImage('syzs' + item + '.png')" :src="$imgUrl + 'syzs' + item + '.png'" alt="">
            </div>
            <div class="listItem listItem1" v-for="item, index in zzzsurl.slice(3)" :key="'b' + index">
              <img @click="viewImage('syzs' + item + '.png')" :src="$imgUrl + 'syzs' + item + '.png'" alt="">
            </div>
          </div>
        </div>
        <!-- 合作伙伴 -->
        <div class="hzhb">
          <div class="pbTit">
            <div class="tit1">合作伙伴</div>
          </div>
          <div class="hzhb_info">
            <img src="@/assets/images/sy11.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      carousList: [
        {
          imgUrl: 'banner1.jpg',
          enDesc: 'WE SERVE FOR CREATING VALUE',
          chDesc: '我们为创造价值服务'
        },
        {
          imgUrl: 'banner2.jpg',
          enDesc: 'QIDU DATA SLOGAN',
          chDesc: '独为数据耕耘，谨以服务尽心'
        },
      ],
      tdnlData: [
        { imgUrl: "sy03.png", ssfl: "算法设计" },
        { imgUrl: "sy04.png", ssfl: "系统架构" },
        { imgUrl: "sy05.png", ssfl: "程序开发" },
        { imgUrl: "sy06.png", ssfl: "界面设计" },
      ],
      jzcxlist: [
        {
          name: '创造价值',
          desc: '公司始终致力于不单纯提高产品技术竞争力，而是通过为顾客创造更多价值来争取顾客，赢得企业成功。',
        },
        {
          name: '高效率',
          desc: '公司始终坚持为客户提供优质的产品和高效的服务，有效改善客户的系统和工作效率是我们最基本的要求和承诺。',
        },
        {
          name: '超额回报',
          desc: '只有我们的软件确确实实为客户工作效率的提高带来了帮助，让客户的软件投资得到超额的回报，我们的软件才是真正有价值的。',
        },
        {
          name: '客户至上',
          desc: '以客户业务为中心，以客户实际业务需求为导向，从客户利益出发，为客户提供完善的信息化应用解决方案。',
        },
        {
          name: '鼓励创新',
          desc: '公司提供富于挑战性的发展空间，鼓励员工创造性地工作，充分发挥个人价值，从而创造出满意的工作效果。',
        },
        {
          name: '团队合作',
          desc: '在工作中，公司强调相互尊重、理解和有效沟通，从而在平等和谐的合作氛围中创造“1+1>2”的工作效应。',
        },
      ],
      zzzsurl: [1, 3, 2, 5, 4, 6, 7]
    }
  },
  methods: {
    viewImage(name) {
      window.open(this.$imgUrl + name)
    }
  }
}
</script>

<style lang="less" scoped>
@imageUrl: "https://www.west7data.com/qdgw/assets/";

.pbTit {
  font-size: 20px;
  color: #333333;
  font-weight: 600;

  .tit1 {
    color: #BD292B;
    margin-bottom: 10px;
  }

  .tit2 {
    margin-bottom: 30px;
  }
}

.home {
  background-color: #fff;
  padding-bottom: 100px;

  .home_top {
    .carousel_item {
      background-size: 100%;
      background-position-y: bottom;
      font-size: 34px;
      text-align: center;

      .enDesc {
        height: 40px;
        color: #4a4a4a;
      }

      .chDesc {
        margin-top: 20px;
        font-size: 70px;
        color: #8c8c8c;
      }
    }


    /*公司简介*/
    .GSJJ {
      background: #fff;
      // border-bottom: 1px solid #eee;
      padding-bottom: 20px;
      margin-bottom: 30px;

      .tit {
        overflow: hidden;

        .name {
          width: 100%;
          font-size: 24px;
          position: relative;
          font-family: "PingFang-SC-Semibold";
          margin: 60px 0 0px 0;
          text-align: center;
          line-height: 24px;
        }

        .bgname {
          font-family: "PingFang-SC-Semibold";
          font-size: 36px;
          color: #c8cfd6;
          text-align: center;
          margin-top: -24px;
        }

      }

      .js {
        font-size: 16px;
        color: #212121;
        text-align: justify;
        line-height: 26px;
        font-weight: 400;
      }
    }

    // 朝气蓬勃
    .zqpb {
      margin-bottom: 60px;

      .zqpb_tdnl {
        align-items: end;

        .left {
          flex: 1;

          .st {
            font-size: 15px;
            color: #384499;
            font-weight: 600;
            margin-bottom: 30px;
          }

          .info {
            font-size: 14px;
            color: #384499;
            font-weight: 400;

            .list {
              text-align: center;
            }

            .desc {
              font-family: SourceHanSansCN-Normal;
            }
          }
        }

        .right {
          flex-shrink: 0;
          margin-left: 80px;
          padding: 20px 0;
          width: 300px;
          height: fit-content;
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
          font-size: 14px;
          color: #6A6A6A;
          text-align: justify;
          line-height: 26px;
          font-weight: 400;
        }
      }
    }

    // 价值创新
    .jzcx {
      margin-bottom: 40px;

      .jzcx_info {
        flex-wrap: wrap;

        .list {
          width: 44%;
          font-size: 15px;
          color: #384499;
          margin-bottom: 20px;

          .name {
            font-weight: 600;
            margin-bottom: 10px;
          }

          .desc {
            font-size: 14px;
            color: #6A6A6A;
            text-align: justify;
            line-height: 20px;
            font-weight: 400;
          }
        }
      }
    }

    .fwly_info,
    .jscx_info {
      font-size: 14px;
      color: #6A6A6A;
      text-align: justify;
      line-height: 26px;
      font-weight: 400;
    }

    // 拓展服务领域
    .fwly {
      margin-bottom: 60px;
    }

    // 信息技术创新
    .jscx {
      margin-bottom: 60px;
    }

    // 资质证书
    .zzzs {
      margin-bottom: 40px;

      .tit1 {
        margin-bottom: 40px;
      }

      .zzzs_info {
        flex-wrap: wrap;
        // overflow: auto;

        .listItem {
          margin-bottom: 20px;
          box-shadow: 0 0 20px #eee;
          height: 240px;

          img {
            height: 100%;
            width: auto;
            cursor: pointer;
          }
        }

        .listItem1 {
          height: 340px;
        }
      }
    }

    .hzhb {
      .pbTit {
        padding-bottom: 30px;
      }


      img {
        width: 100%;
      }
    }
  }

}
</style>
<style lang="less">
.home {
  background-color: #fff;

  .home_top {
    .el-carousel__indicator.is-active button {
      background: #BD292B;
    }

    .home_top_carous {
      .el-carousel__button {
        width: 8px;
        height: 8px;
        background: #00000020;
        border-radius: 50%;
      }

      .el-carousel__indicator.is-active button {
        background: #5665d8;
      }

    }


  }

}
</style>
