
import HomeView from '../views/HomeView.vue'

let route = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/contact_us',
        name: 'contact_us',
        component: () => import('../views/contact_us'),
    },
    {
        path: '/service',
        name: 'service',
        component: () => import('../views/service'),
    },
    {
        path: '/xqy',
        name: 'xqy',
        component: () => import('../views/xqy'),
    },
    {
        path: '/case',
        name: 'case',
        component: () => import('../views/case'),
    },
]
export default route