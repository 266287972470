<template>
  <div id="app">
    <el-backtop target="#app"></el-backtop>
    <Header></Header>
    <!-- 首页 -->
    <div class="main">
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Breadcrumb from '@/components/Breadcrumb'
import { mapGetters } from 'vuex'
export default {
  name: 'app',
  components: { Header, Footer, Breadcrumb },
  computed: {
    url1() {
      return this.$getPath(this.$route.path)[1];
    },
    url2() {
      return this.$getPath(this.$route.path)[2];
    },
    url3() {
      return this.$getPath(this.$route.path)[3];
    },
    url4() {
      return this.$getPath(this.$route.path)[4];
    },
    ...mapGetters([
      'token'
    ])
  },
  watch: {
    $route() {
      document.documentElement.scrollTop = 0
    }
  },
  created() {
  },
  methods: {
  }
}
</script>
<style lang="less">
#app {
  height: 100vh;
  overflow-x: auto;
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #eee;
}

.main {
  min-height: calc(100vh - 600px);

  .jjnav {
    height: 66px;
    background: #FFFFFF;
  }
}
</style>
