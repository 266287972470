<template>
    <div class="footer">
        <div class="global_center ">
            <!-- <div class="top">
                <div class="top_list flexBet">
                    <div class="itemList" v-for="item, index in list" :key="index">
                        <div class="header">
                            {{ item.tit }}
                        </div>
                        <div class="body">
                            <div class="childItem" v-for="childItem, indexChild in item.child" :key="indexChild">
                                <router-link v-if="childItem.path" :to="childItem.path">{{ childItem.tit }}</router-link>
                                <span v-else-if="childItem.desc">
                                    {{ childItem.tit }}：{{ childItem.desc }}
                                    <div style="margin-left: 55px;">{{ childItem.desc1 }}</div>
                                </span>
                                <img v-if="childItem.imgurl" :src="require('../../assets/images/' + childItem.imgurl)"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="bottom">
                <div class="botList flexCenter">
                    <div class="botItem">Copyright © 2019-{{ cutYear }}兰州七度数聚技术有限公司版权所有</div>
                    <div class="botItem">
                      <a href="https://beian.miit.gov.cn/" target="_blank">陇ICP备19000772号</a>
                    </div>
<!--                    <div class="botItem">-->
<!--                        <a target="_blank"-->
<!--                            href="https://beian.mps.gov.cn/portal/registerSystemInfo?recordcode=62010502000412">-->
<!--                            <img src="../../assets/images/beian.png" style="float:left;width: 15px;height: 15px">-->
<!--                            <span style="float:left;margin-left: 5px"> 甘公网安备62010502000412号</span>-->
<!--                        </a>-->
<!--                    </div>-->

                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer',
    data() {
        return {
            cutYear: new Date().getFullYear(),
            list: [
                {
                    tit: "关于我们",
                    path: '',
                    child: [
                        {
                            tit: '公司简介',
                            path: '/about_us/index?id=1',
                        },
                        {
                            tit: '企业精神',
                            path: '/about_us/index?id=1',
                        },
                        {
                            tit: '加入我们',
                            path: '/about_us/index?id=1',
                        },
                        {
                            tit: '所获荣誉',
                            path: '/about_us/index?id=1',
                        },
                        {
                            tit: '技术交流',
                            path: '/community/',
                        },
                    ]
                },
                {
                    tit: "行业方案",
                    path: '',
                    child: [
                        {
                            tit: '环西部火车游',
                            path: '/case/travel',
                        },
                        {
                            tit: '智慧驾培',
                            path: '/case/driving_training',
                        },
                        {
                            tit: '小七公益',
                            path: '/case/welfare',
                        }
                    ]
                },
                {
                    tit: "服务能力",
                    path: '',
                    child: [
                        {
                            tit: '软件定制开发',
                            path: '/service/software',
                        },
                        {
                            tit: '互联网+',
                            path: '/service/internet',
                        },
                        {
                            tit: '智能系统集成',
                            path: '/service/system',
                        },
                        {
                            tit: '人工智能应用',
                            path: '/service/intelligence',
                        }
                    ]
                },
                {
                    tit: "新闻动态",
                    path: '',
                    child: [
                        {
                            tit: '企业新闻',
                            path: '/news/index/1',
                        },
                        {
                            tit: '行业新闻',
                            path: '/news/index/2',
                        }
                    ]
                },
                {
                    tit: "联系我们",
                    path: '',
                    child: [
                        {
                            tit: '联系电话',
                            desc: '0931-2146117',
                            desc1: '（工作日：09:30-18:30）',
                        },
                        {
                            tit: '客服邮箱',
                            desc: 'Business@west7data.com',
                        },
                        {
                            tit: '地址',
                            desc: '甘肃省兰州市安宁区万新南路高新什字16号科技产业孵化园综合楼2层212号',
                        }
                    ]
                },
                {
                    tit: "关注我们",
                    path: '',
                    child: [
                        {
                            tit: '',
                            imgurl: 'wechat_01.png',
                        }
                    ]
                },
            ]
        }
    },
    created() {
    }
}
</script>

<style lang="less" scoped>
.footer {
    // background: #111010;
    // height: 250px;
    width: 100%;
    color: #FFFFFF;
    // padding-top: 50px;

    a {
        color: inherit;
        text-decoration: none;
    }

    .top {
        font-size: 14px;
        color: #999;

        .top_list {
            .itemList {
                max-width: 220px;

                .header {
                    padding-bottom: 16px;
                }

                .body {
                    color: #666;
                    margin-left: 1px;
                }

                .childItem {
                    font-size: 12px;
                    margin-top: 8px;

                    img {
                        width: 90px;
                        height: 90px;
                        border: 1px solid #ffffff;
                    }
                }
            }
        }
    }

    .bottom {
        // border-top: 1px solid #232323;
        // margin-top: 40px;
        font-size: 12px;

        .botList {
            color: #666;
            height: 60px;
            .botItem{
                &:not(:last-child){
                    margin-right: 20px;
                }
            }
        }
    }
}
</style>