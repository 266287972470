import Vue from 'vue'
import VueRouter from 'vue-router'
import route from './route'
import { getToken } from '@/utils/auth'
Vue.use(VueRouter)

const routes = route

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  next()
})
export default router
